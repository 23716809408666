.banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;
}

.card-b {
  position: relative;
  width: 100%; /* Full width of the banner */
  height: 300px;
  padding: 20px;
  background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button-left,
.button-right,
.or-circle {
  margin-top: 20px;
}

.button-left {
  font-weight: bold;
  background-color: white;
  border: 1px solid rgba(175, 230, 255, 0.5);
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
              0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
              inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
              inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 20px 40px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  font-family: 'Roboto', sans-serif;
  
}

.button-left:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

.button-right {
  background-color: #082b2a;
  border: 1px solid rgba(175, 230, 255, 0.5);
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
              0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
              inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
              inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 20px 40px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  font-family: 'Roboto', sans-serif;
}

.button-right:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

.card-b span {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  padding: 40px;
}

.pic1 {
  position: absolute;
  left: 0;
rotate: 325deg;
  width: 260px;
  height: auto;
  
}

.pic2 {
  position: absolute;
  right: 0;
margin-right: 10px;
  width: 200px;
  height: auto;
}

.or-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .card-b {
    height: 170px; /* Adjust the height of the banner */
    padding: 10px;
    width: 100%; /* Adjust padding for tighter spacing */
  }

  .card-b span {
    font-size: 22px; /* Reduce font size of the heading */
    padding: 5px;
    z-index: 1000; /* Adjust padding to reduce spacing */
  }

  .button-left,
  .button-right {
    font-size: 15px ;/* Reduce button font size */
    padding: 10px  20px 10px 20px ; 
    display: flex;
    align-items: center;
    justify-content: center; /* Center text within button */
    text-align: center; 
    
   
     /* Adjust button padding */
  }
  .buttons-container {
   transform: scale(0.7);
z-index: 1000;
margin-top: -5px;

   
 
  }

  .pic1 {
    width: 80px;
    left: -7px; /* Adjust width of pic1 */
   
  }

  .pic2 {
    width: 60px; /* Adjust width of pic2 */
    right: -8px; /* Ensure it is not hidden off the screen */
   /* Ensure it stays above the background but below text */
    display: block;
   
  }  
  
  .or-circle {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;

    border-radius: 50%;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 15px;
  }
}

