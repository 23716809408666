.hero{
  display:flex;
  justify-content: space-between;
  
}
 .left-h{
  
    padding:2rem;
    padding-top: 0.4rem;
    flex: 3;
    display:flex;
    
    flex-direction: column;
 }

 .right-h{
    flex:1;
    position: relative;
    background-color: #a1eee0;
    
    
 }

 .align {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
  padding-right: 60PX;
  align-items: flex-start;
}
.hero-blur{
  width: 20rem;
  height: 30rem;
  left: 0px;
  opacity: 0.8;
  
}
.hero-text {
  color: #082b2a;
  font-size: 60px;
  font-weight: bold;
  text-overflow: inherit;
  margin-top: 20px;
  margin-right:370px;
  font-family: 'Roboto', sans-serif;
  
}

.subtext-h {
  color: #19887f;
  font-size: 20px;
  text-align: left;
  margin-right: 380PX;
  font-family: 'Roboto', sans-serif;
 
  
 
}
.figures{
  
    display: flex;
    gap: 2rem;
   
}
.figures>div{
    display: flex;
    flex-direction:column;
}

.figures>div>span:nth-of-type(1){
    color: #082b2a;
    font-family: 'Roboto', sans-serif;
    
    font-size: 40px;
}

.figures>div>span:nth-of-type(2){
    color:#19887f;
    font-size: 18px;
   
}

.btn1:is(:hover, :focus-visible) { transform: translateY(-4px); }
.btn2:is(:hover, :focus-visible) { transform: translateY(-4px); }

.btn2{
      --clr-font-main: hsla(0 0% 20% / 100);
      --btn-bg-1: hsla(194 100% 69% / 1); /* Original styles can be removed if not needed */
      --btn-bg-2: hsla(217 100% 56% / 1);
      --btn-bg-color: hsla(360 100% 100% / 1);
      --radii: 0.5em;
  
      cursor: pointer;
      padding: 0.9em 1.4em;
      min-width: 120px;
      min-height: 44px;
      font-size: var(--size, 1rem);
      font-family: "Segoe UI", system-ui, sans-serif;
      font-weight: 500;
      transition: 0.8s;
      border: none;
      border-radius: var(--radii);
      color: var(--btn-bg-color); /* Adjust if needed */
  
      /* Modified background for button */
      background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
      box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
    }


  
  .btn1 {     --clr-font-main: hsla(0 0% 20% / 100);
    --btn-bg-1: hsla(194 100% 69% / 1); /* Original styles can be removed if not needed */
    --btn-bg-2: hsla(217 100% 56% / 1);
    --btn-bg-color: hsla(360 100% 100% / 1);
    --radii: 0.5em;

    cursor: pointer;
    padding: 0.9em 1.4em;
    min-width: 120px;
    min-height: 44px;
    font-size: var(--size, 1rem);
    font-family: "Segoe UI", system-ui, sans-serif;
    font-weight: 500;
    transition: 0.8s;
    border: none;
    border-radius: var(--radii);
    color: var(--btn-bg-color); /* Adjust if needed */

    /* Modified background for button */
    background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  }

    
  
.herobuttons{
    display: flex;
  
  gap: 2rem;
 
}

.anim1{

  width: 200px; /* Adjust width as needed */
  height: 260px; /* Adjust height as needed */
  border-radius: 10px; /* Directly specify the radius */
  overflow: hidden;
  position: relative; /* Position absolutely */
  top: 3rem; /* Adjust top position */
  left: 7rem; /* Adjust left position */
  background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
              0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
              inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
              inset -4px -4px 8px rgba(19, 95, 216, 0.35);
}



.image1{
 max-width: 100%;

}

.tagline {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-align: left;
  margin-top: 3rem;
  padding:2rem;
  padding-top: 1.5rem;
  background-color: #a1eee0;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 15px;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 
    0px 0px 10px rgba(68, 198, 177, 0.5),  
    0px 5px 5px -1px rgba(43, 166, 227, 0.25); 
}

.vip {
  z-index: 2;
  position: relative; /* Ensure this is positioned above taglineInner */
}

.taglineInner {
  position: absolute;  
  background-color: #186d67;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1; 
  top: 10%; /* Adjust as needed */
  width: 40%; /* Adjust width to fit within padding */
  box-shadow: 
  0px 0px 10px rgba(24, 109, 103, 0.5),  
  0px 5px 5px -1px rgba(24, 109, 103, 0.25);
}

/* Ensure the .vip element has higher z-index and proper position */
.vip {
  position: relative;
  z-index: 2;
 
}

.hero-image{
 position: absolute;
  top: 11rem;
  right: 17rem;
  width: 23rem;
  transform: translateY(-210Px); 
  filter: drop-shadow(0 0 0.75rem rgb(24, 109, 103));
  
}


.back-image{
  top:8rem;
  right: 15rem;
  width: 25rem;
  transform: translateY(-210Px); 
  position: absolute;
  z-index: -1;
 margin-left: 100px;
margin-right: 120px;
  
  
}

.buttona{
  
  margin-top: 40px;
  margin-left: 80px;
}
@media screen and (max-width: 768px) 
{

  .hero {
    flex-direction: column;
    align-items: center;
   
    margin-bottom: -100px;
    
 /* Add padding to the hero section */
  }
  
  .hero-blur {
    width: 14rem;
  }
  
  .tagline {
    margin-top:0;
    margin-bottom: 2rem;
    align-self: center;
    transform: scale(0.8);

  }
  
  .herobuttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem; 
    padding: 0;
  }
  
  .hero-text {
    
    font-size: 2rem;
    text-align: center;
    margin: 0 2rem; /* Adjust margin to ensure no cutting at edges */
    
    line-height: 1.2; /* Adjust line-height for better readability */
    width: 100%; /* Ensure the text takes up full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .subtext-h {
    font-size: 1rem;
    text-align: center;
    margin: 0 1rem; /* Adjust margin to ensure no cutting at edges */
    padding: 0;
    line-height: 1.5; /* Adjust line-height for better readability */
    width: 100%; /* Ensure the text takes up full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .figures > div > span:nth-of-type(1) {
    font-size: 24px;
    font-weight: bold;
  }
  
  .figures > div > span:nth-of-type(2) {
    font-size: small; 
  }
  
  
  

  
  .btn1,
  .btn2 {
    padding: 0.8em 1em; 
    min-width: 100px;
    min-height: 36px;
    font-size: 0.8rem;
  }
  
 
  
  .left-h {
    align-items: center;
    text-align: center;
    padding: 1rem;
     order: 1;
    
  }
  
  .align {
    align-items: center;
    text-align: center;
    padding-right: 0;
  }
  .right-h {
    background: transparent;
    margin-top: 8rem;
    order: 2;
    padding: 0;

   

    .buttona {

    font-size: 0.6rem;
    padding: 0.2em 0.2em;
    position: relative;
    top: 9rem;
    left: -6.8rem;
  } .back-image {
    position: relative;
    width: 10rem;
    top: -17rem;
    right: -7rem;
  }.hero-image {
    width: 10rem;
    top: -1rem;
    right: -2.5rem; 
  }

  .anim1 {
    width: 120px;
    height: 155px;
    top: -7rem;
    left: -1.5rem;
  }
}
}